<template>
	<div class="bg-container page">
		<div class="bg-wrapper">
			<div class="register">
				<van-nav-bar class="nav-bar">
					<template #left>
						<van-icon name="arrow-left" color="#767678" @click="back()" />
					</template>
					<template #right>
						<svg @click="$router.push({path: 'Language'})" xmlns="http://www.w3.org/2000/svg" class="icon-language" width="25" height="25" viewBox="0 0 24 24" stroke-width="1.5" stroke="#767678" fill="none" stroke-linecap="round" stroke-linejoin="round">
							<path stroke="none" d="M0 0h24v24H0z" fill="none" />
							<path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
							<path d="M3.6 9h16.8" />
							<path d="M3.6 15h16.8" />
							<path d="M11.5 3a17 17 0 0 0 0 18" />
							<path d="M12.5 3a17 17 0 0 1 0 18" />
						</svg>
					</template>
				</van-nav-bar>
				<div class="wrapper">
					<div class="logo-container">
						<div class="logo-wrapper">
							<img class="logo-img" :src="this.$store.getters.getBaseInfo.ico !== undefined ? this.$store.getters.getBaseInfo.ico : ''" />
						</div>
					</div>
					<div class="title">{{ $t("register") }}</div>
					<div class="loginForm">
						<van-field v-model="username" clearable class="input" :placeholder="$t('username')" />
						<van-field v-model="password" :type="passwordType" class="input" :placeholder="$t('password')">
							<template slot="right-icon">
								<van-icon :name="passwordType === 'password' ? 'closed-eye' : 'eye-o'" @click="switchPasswordType" />
							</template>
						</van-field>
						<van-field v-model="code" clearable class="input" :placeholder="$t('code')" />
						<div class="agreement">
							<van-checkbox v-model="checked" />
							<span class="agreement-text">{{ $t("agree") }}</span>
						</div>
						<van-button class="login-btn" type="primary" size="normal" @click="doRegister()">{{ $t("register") }}</van-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	model: {
		prop: "inputValue",
		event: "input",
	},
	props: {
		inputValue: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			checked: true,
			username: "",
			code: "",
			password: this.inputValue,
			passwordType: "password",
		};
	},
	methods: {
		switchPasswordType() {
			this.passwordType = this.passwordType === "password" ? "text" : "password";
		},
		back() {
			return window.history.back();
		},
		doRegister() {
			if (this.username === "" || this.username === null || this.username === undefined) {
				this.$toast.fail(this.$t("pls_username"));
				return false;
			}
			if (this.password === "" || this.password === null || this.password === undefined) {
				this.$toast.fail(this.$t("pls_password"));
				return false;
			}
			if (this.code === "" || this.code === null || this.code === undefined) {
				this.$toast.fail(this.$t("pls_code"));
				return false;
			}
			if (!this.checked) {
				this.$toast.fail(this.$t("pls_agree"));
				return false;
			}
			this.$http({
				method: "post",
				data: {
					username: this.username,
					password: this.password,
					code: this.code,
				},
				url: "member_register",
			}).then(res => {
				if (res.code === 200) {
					this.$toast.success(this.$t("regis_success"));
					localStorage.setItem("token", res.data);
					this.$router.push("Home");
				} else {
					this.$toast.fail(res.msg);
				}
			});
		},
	},
	created() {
		if (localStorage.getItem("token")) {
			return window.history.back();
		}
	},
};
</script>

<style lang="less" scoped>
@import "../../assets/css/base.css";
.bg-container .bg-wrapper {
	background: #fff;
}
.bg-container {
	background: #fff;
}
.register {
	height: 100vh;
	overflow: hidden;
}
.bg-container .bg-wrapper .register .nav-bar {
	background: 0 0;
}
.register .wrapper {
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}
.logo-container {
	margin: 0 auto;
	width: 120px;
	min-height: 100px;
}
.logo-container .logo-wrapper {
	position: relative;
	padding-bottom: 62.5%;
}
.logo-container .logo-wrapper .logo-img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	-o-object-fit: contain;
	object-fit: contain;
}
.register .wrapper .title {
	line-height: 30px;
	text-align: center;
	font-size: 25px;
	font-weight: 700;
	color: #767678;
	letter-spacing: 1px;
}
.register .wrapper .loginForm {
	padding: 15px 30px;
}
.register .wrapper .loginForm .input {
	padding: 5px 10px;
	margin-top: 15px;
	border-radius: 10px;
	text-align: left;
	line-height: 30px;
	font-size: 14px;
	color: #4e4e4e;
	border: 1px solid #c5ccd2;
}
::v-deep .van-field__right-icon .van-icon {
	font-size: 17px;
}
::v-deep .van-icon {
	font-size: 14px;
}
.register .wrapper .loginForm .reset-text {
	margin: 15px 15px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.register .wrapper .loginForm .reset-text span {
	color: #767678;
	font-size: 13px;
	font-weight: 500;
	line-height: 15px;
}
.register .wrapper .loginForm .register-text {
	margin: 10px 0;
	display: flex;
	align-items: center;
	justify-content: center;
}
.register .wrapper .loginForm .register-text span {
	color: #767678;
	font-size: 13px;
	font-weight: 500;
	line-height: 20px;
}
.register .wrapper .loginForm .login-btn {
	margin-top: 5px;
	width: 100%;
	height: 40px;
	border-radius: 10px;
	color: #fff;
	background: #a5adb8;
	font-size: 14px;
	font-weight: bolder;
	border: none;
}
.register .wrapper .loginForm .agreement {
	margin: 15px 0;
	display: flex;
	align-items: center;
	justify-content: center;
}
.register .wrapper .loginForm .agreement .agreement-text {
	margin-left: 10px;
	font-size: 11px;
	color: #303038;
	font-weight: 500;
	line-height: 20px;
}
::v-deep .agreement {
	font-size: 14px;
}
::v-deep .agreement .van-checkbox__icon {
	font-size: 18px;
}
::v-deep .agreement .van-checkbox__icon--checked .van-icon {
	color: #fff;
	border-color: #09aa5c;
	background: #09aa5c;
}
.icon-language {
	position: absolute;
	top: 5px;
	right: 10px;
}
</style>
