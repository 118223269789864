<template>
	<div class="container page">
		<div class="header">
			<van-nav-bar :title="$t('history_withdraw')" class="nav-bar">
				<template #left>
					<van-icon name="arrow-left" color="#fff" @click="back()" />
				</template>
			</van-nav-bar>
		</div>
		<div class="main">
			<van-pull-refresh v-model="isLoading">
				<van-empty v-if="list.length === 0" :description="$t('no_data')" />
				<div v-else class="item_list" v-for="(v, key) in list" :key="key">
					<div class="topInfo">
						<span v-if="v.status === 2" style="color: #07c160">{{ v.status_text == "Chờ xác minh" ? $t("pending") : v.status_text }}</span>
						<span v-else style="color: black">{{ v.status_text == "Chờ xác minh" ? $t("pending") : v.status_text }}</span>
						<span>{{ $t("money") }}: -{{ Number(v.money).toLocaleString() }}</span>
					</div>
					<div class="desc">
						<span>{{ $t("content") }}：{{ v.desc }}</span>
					</div>
					<div class="time">
						<span>{{ $t("created_at") }}：{{ v.create_time }}</span>
					</div>
					<div class="time">
						<span>{{ $t("updated_at") }}: {{ v.update_time == "Chờ xác minh" ? $t("pending") : v.update_time }}</span>
					</div>
				</div>
			</van-pull-refresh>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			isLoading: false,
			list: [],
		};
	},
	methods: {
		back() {
			return window.history.back();
		},
		onRefresh() {
			setTimeout(() => {
				this.$toast(this.t("renew"));
				this.isLoading = false;
			}, 500);
		},
		getUserWithdrawList() {
			this.$http({
				method: "get",
				url: "user_get_withdraw_list",
			}).then(res => {
				if (res.code === 200) {
					this.list = res.data;
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			});
		},
	},
	created() {
		if (!localStorage.getItem("token")) {
			this.$router.push({path: "/Login"});
		} else {
			this.getUserWithdrawList();
		}
	},
};
</script>

<style lang="less" scoped>
@import "../../assets/css/base.css";
::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
	color: #000000;
	font-size: 16px;
}

::v-deep .van-loading__text {
	color: #000000;
	font-size: 16px;
}
.container .main {
	position: relative;
	overflow: auto;
	background-color: #f2f2f5;
	height: 100%;
	padding: 0 10px;
}

.item_list {
	padding: 15px 15px;
	margin: 30px 10px;
	background: #fff;
	border-radius: 10px;
	line-height: 30px;
}

.item_list .topInfo span {
	flex: 1;
	font-size: 16px;
	font-weight: 700;
	color: #09aa5c;
}
.item_list .time span {
	flex: 1;
	font-size: 13px;
	font-weight: 500;
	color: #000;
}

.item_list .topInfo span:last-child {
	float: right;
}
.item_list .desc span {
	font-size: 13px;
	font-weight: 700;
	color: #9b9b9b;
}
</style>
