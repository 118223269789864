<template>
	<div class="mine page">
		<div class="page-bg"></div>
		<div class="wrapper">
			<van-pull-refresh v-model="isLoading">
				<div class="header">
					<div class="cskh" @click="toService()"><img src="/img/mine/service.png" /></div>
					<van-nav-bar class="nav-bar">
						<template #right>
							<van-icon name="setting-o" @click="showSetting()" color="#fff" />
							<svg @click="$router.push({path: 'Language'})" xmlns="http://www.w3.org/2000/svg" class="icon-language" width="25" height="25" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
								<path stroke="none" d="M0 0h24v24H0z" fill="none" />
								<path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
								<path d="M3.6 9h16.8" />
								<path d="M3.6 15h16.8" />
								<path d="M11.5 3a17 17 0 0 0 0 18" />
								<path d="M12.5 3a17 17 0 0 1 0 18" />
							</svg>
						</template>
					</van-nav-bar>
					<div class="user-wrapper" @click="doLogin()">
						<van-image round class="user_img" :src="this.userInfo.header_img">
							<template v-slot:loading>
								<van-loading type="spinner" />
							</template>
						</van-image>
						<div class="login-content">
							<!--<p class="login-btn" >{{this.userInfo.username}}</p>
               <p class="login-label">VIP {{this.userInfo.vip ? this.userInfo.vip : "0"}}</p>-->
							<p class="login-btn" style="font-size:20px">ID: {{ this.userInfo.id ? this.userInfo.id : "*****" }}</p>
						</div>
					</div>
					<div class="account-money">
						<div class="accounts u-flex u-row-between">
							<p>{{ $t("money") }}</p>
							<p class="accounts-money">{{ this.userInfo.money ? Number(this.userInfo.money).toLocaleString() : "*****" }}</p>
						</div>
						<div class="u-flex u-row-around deposit">
							<button @click="doWithdrawal()" class="van-button van-button--default van-button--small van-button--round" style="margin-right: 10px; color: #fff; background: #09aa5c; border: 0px; font-size: 16px;">
								<div class="van-button__content">
									<span class="van-button__text"> {{ $t("withdraw") }} </span>
								</div>
							</button>
						</div>
					</div>
					<div class="money_zz"></div>
				</div>
				<div class="content">
					<div class="box-mine">
						<!--<div class="item-box-mine">
                <img src="/img/mine/icon1.png"/>
                <span>Lịch sử nạp sao</span>
              </div>-->
						<div class="item-box-mine" @click="$router.push({path: '/WithdrawRecord'})">
							<img src="/img/mine/icon4.png" />
							<span>{{ $t("history_withdraw") }}</span>
						</div>
						<div class="item-box-mine" @click="$router.push({path: '/GameRecord'})">
							<img src="/img/mine/icon2.png" />
							<span>{{ $t("history_play") }}</span>
						</div>
						<div class="item-box-mine" @click="$router.push({path: '/Infomation'})">
							<img src="/img/mine/icon6.png" />
							<span>{{ $t("info") }}</span>
						</div>
						<!--<div class="item-box-mine" @click="$router.push({path:'/Personalreport'});">
                <img src="/img/mine/icon3.png"/>
                <span>Tài khoản của tôi</span>
              </div>
              <div class="item-box-mine" @click="$router.push({path:'/Address'});">
                <img src="/img/mine/icon5.png"/>
                <span>Địa chỉ</span>
              </div>-->
						<div class="item-box-mine" @click="$router.push({path: '/SetLoginPassword'})">
							<img src="/img/mine/icon7.png" />
							<span>{{ $t("pwd_login") }}</span>
						</div>
						<div class="item-box-mine" @click="$router.push({path: '/Setbank'})">
							<img src="/img/mine/icon8.png" />
							<span>{{ $t("bind_bank") }}</span>
						</div>
						<div class="item-box-mine" @click="toService()">
							<img src="/img/mine/icon9.png" />
							<span>{{ $t("recharge") }}</span>
						</div>
					</div>
				</div>
			</van-pull-refresh>
			<van-button class="sign-out" type="primary" size="normal" @click="loginout()">{{ this.userInfo.id ? $t("logout") : $t("login") }}</van-button>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			userInfo: {},
			menu_top: 40,
			isLoading: false,
		};
	},
	methods: {
		refresh() {
			this.isLoading = true;
			setTimeout(() => {
				this.isLoading = false;
				if (localStorage.getItem("token")) {
					this.$toast(this.$t("renew"));
				} else {
					this.$router.push({path: "/Login"});
				}
			}, 500);
		},
		exit() {
			this.$toast(this.$t("pls_contact"));
		},
		showSetting() {
			if (localStorage.getItem("token")) {
				this.$router.push({path: "/Setting"});
			} else {
				this.$router.push({path: "/Login"});
			}
		},
		toNotice() {
			if (localStorage.getItem("token")) {
				this.$router.push({path: "/Notice"});
			} else {
				this.$router.push({path: "/Login"});
			}
		},
		onRefresh() {
			setTimeout(() => {
				this.isLoading = false;
				if (localStorage.getItem("token")) {
					this.getUserInfo();
					this.$toast(this.$t("renew"));
				} else {
					this.$router.push({path: "/Login"});
				}
			}, 500);
		},
		loginout() {
			localStorage.removeItem('token');
			this.$router.push({path: "/Login"});
		},
		doLogin() {
			if (localStorage.getItem("token")) {
				this.$router.push({path: "/Infomation"});
			} else {
				this.$router.push({path: "/Login"});
			}
		},
		doPay() {
			if (localStorage.getItem("token")) {
				this.$router.push({path: "/Recharge"});
			} else {
				this.$router.push({path: "/Login"});
			}
		},
		doWithdrawal() {
			this.$http({
				method: "get",
				url: "user_get_bank",
			}).then(res => {
				if (res.data.is_bank) {
					this.$router.push("withdraw");
				} else {
					this.$router.push("Setbank");
					this.$toast.fail(this.t("pls_bind_bank"));
				}
			});
		},
		toService() {
			this.$toast(this.$t("pls_contact"));
			/*if(this.$store.getters.getBaseInfo.iskefu == 1){
        this.$router.push("ServiceOnline");
      }else {
        this.$toast.fail("Tính năng bị vô hiệu hóa!");
      }*/
		},
		getUserInfo() {
			this.$http({
				method: "get",
				url: "user_info",
			}).then(res => {
				if (res.code === 200) {
					this.userInfo = res.data;
					this.menu_top = 15;
					if (this.userInfo.status !== 1) {
						this.$toast(this.t("offline"));
						localStorage.removeItem('token');
						this.$router.push({path: "/Login"});
					}
				} else if (res.code === 401) {
					this.$toast(res.msg);
				}
			});
		},
	},
	created() {
		if (localStorage.getItem("token")) {
			this.getUserInfo();
		} else {
			this.userInfo.username = this.$t("login_regis");
			this.userInfo.ip = this.$t("text_login_regis");
			this.userInfo.header_img = "img/mine/avatar.png";
		}
	},
};
</script>

<style scoped>
.mine {
	position: relative;
	bottom: 10px;
	background: linear-gradient(1turn, #fff, #09aa5c);
}
.mine .wrapper {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
}
.nav-bar {
	background: transparent;
}
.mine .header {
	background: linear-gradient(rgb(239 51 64 / 90%), rgb(239 51 64 / 30%)), url(/img/mine/user_head.png) no-repeat 50% / cover;
	position: relative;
}
::v-deep .van-nav-bar__right {
	top: 10px;
}
::v-deep .van-nav-bar__content {
	height: 40px;
}
::v-deep .van-hairline--bottom::after {
	border-bottom-width: 0px;
}
.mine .header .van-nav-bar .van-icon {
	font-size: 30px;
	margin-top: 10px;
}
.mine .header .user-wrapper {
	display: flex;
	align-items: center;
	margin: 0px 25px 0px 25px;
}
.mine .user_img {
	height: 40px;
	width: 40px;
}
.account-money {
	background: url(/img/mine/user_balance.png) no-repeat;
	background-size: cover;
	height: 110px;
	padding: 0 20px 30px;
	color: #fff;
	width: 90%;
	margin: 0 auto;
	margin-top: 19px;
	background-position: center;
	border-radius: 10px;
}
.account-money .accounts {
	font-size: 14px;
	color: hsla(0, 0%, 100%, 0.6);
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.account-money .accounts-money {
	color: #f2d8be;
	font-size: 16px;
	font-weight: bold;
}
.account-money .deposit {
	position: relative;
	margin-top: 8px;
	display: flex;
	justify-content: space-around;
	align-items: center;
}
.money_zz {
	position: absolute;
	bottom: -5px;
	left: 0;
	width: 100%;
	height: 35px;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAu4AAACOBAMAAACcWJCKAAAAHlBMVEVHcEwAAAAAAAAEBATu7u7f39+8vLz////8/Pz39/c8OwPaAAAACnRSTlMABAsSjmU9/9y2RVndewAABPVJREFUeAHs1YlR9TAMxHGpg6w7sNMB6b83YjnZ8WhsxH09/76TG/5vEfIfLMuyLMuyqMryzbG36vr7ay2bkau1wpHPt7TYXWPc9IbTGv/n0YqhW2oBvK1aw/8cLbnAE3gt+ScNf51zUdC0O2k//BX/46cl7k4bVvx32GYz990TzYYPXWfn9SufJu9DCxJSMRn5hAF1y4cs45UL5s270IAklNyw/TB+S87lr+nTVVwxw7q5qf8KUi6XdCon8ObMlm/w8PXVMPmsOdLVnJ0LhNl9+yg+tIF50OKCuWRK15wSJJXixUeH9Zn/Yca/RZecyTnzPEgsubD8qH3Bi/F5bRr84/wab9y0XGw4lIuU6snE8YP6zP+v+m+cuAbFmXzSfDfllCTX7PtxGsfPYXxSnp7bn+6vF2Hw+LBUqRo3t8zWWOxZx/3cp/sBmcZPBgjym+0C+kv7rmtBhD2i5NZ8r2za0q7MUd3tzUvxc1DfdL2VAPrNuQWvweIuuW9erpkzs3W3K0N8ZAbDd+8/IxsE+tpKip78DO343GFx3hUmcZiaye2J474zB8Oz/fzg+w8F5Ci/+wbgUx14X7trElc7lN0Pz1Oh6Wk5yMLvbe/HAF+9TOK7j4rU4DVYmtTDL8PgyR/dwcrd4fZqTillZ3iPb7ebMpL7/PD5Y+O5b1/sg8FZfLZyP3Mfnnuf6R49np1w/Blp0j/GLj8t++Dz4lx5kNzt/Qi5+EH9XC5AuuGjPmfHsXSz3nHwZ+7M4LZtGAyjncEbhNxABDyBfNBdvxcQzBWkDULfc6i2LeWqQh/qBH9/qpWsd1OMGMT7nukAmZUrKmfvl84HHf5BwKUPqD+zPGOA/YF/SdA3hRM/A+UaqnP7re+HD+mCCr5/NeO0/vkJ2H4DnuT5qQmVO17FSs7SpL7P3ieS0j3Tx3cuIKe3X/y+CTc4/RfTYPqBW+AZVY3rjcN5JntfuMO9Apxgxn3N24JbwAhkJcl4v8/Oc3Jf4y2N0/n3/gG9z91fYgBK+3jwTgGEPNkH6izM8p9Nj/VVvjNW45nYNu/9BL2TYbxcw9/CsynzxwjEFUHNxGnxKDxUMK7n1o5wDu8Al45JP54yBl+ncmwDVgtFwnG16L0zfLt+9p9xO8VP8Nh4tmau907uuPEN+nFy73a1QFWhbrNvEts5c5N3yhfILx4g48EWXX92nmAnCpXbvIMB8m2gL7zg/2DFngGDKLVN5bxZir3zD81rF9aAMghfteOe6GTvPpRzvrVN6sla3sEw1qXpgwoyivMDHlB1OVFqfn3qvZtJsL8qHr6KCPS8qnFGXu7dbP/40Pgm3mkf9/6R4D0O41t7R/zXeEDhTHxn3lG/HEF/FBa+W+9gyPln/93r9Z1958Dfe/Ii3tE/B9ixbvb9ut7BMC0gEmPYDzGK1A3rPpZ3kO6PD4HE2G0QdpSfaaeeHN87SGnMK9Qicv0nO8RJtNR1PY6JYR/Tu32HdB/zFB9ZVSsTt/ig656v0nXxwe0mE5f8e00zZst2zUf0/qMdOhYAAAAAGORvPYp9hRDevXvH+8O7d7x7x7t3vHvHu3e8e/eOd+94945373j3jnfvePfuHe/e8e4d797x7h3v3r3j3TvevePdO9694927d7x7x7t3vHvHu3e8e8e7d+94945373j3jnfvePfuHe/e8e4d797x7h3v3gnJSBQywpXfQwAAAABJRU5ErkJggg==);
	background-size: 100%;
}
::v-deep .van-loading__spinner {
	height: 40px;
	width: 50px;
}
::v-deep .van-image__error-icon {
	font-size: 30px;
}
.mine .header .user-wrapper .login-content {
	flex: 1;
	margin-left: 15px;
}
.mine .header .user-wrapper .login-content .login-btn {
	display: block;
	font-size: 15px;
	line-height: 0px;
	font-weight: 600;
	color: #333;
}
.mine .header .user-wrapper .login-content .login-label {
	margin-top: 0px;
	font-size: 11px;
	line-height: 11px;
	background: rgba(0, 0, 0, 0.2);
	border-radius: 3px;
	padding: 4px 6px;
	color: hsla(0, 0%, 100%, 0.8);
	display: inline-block;
}
.mine .page-bg {
	height: 100vh;
	background: #fff;
}
.mine .content {
	position: relative;
	padding: 10px 0;
	height: auto;
	background-color: #fff;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
	color: #ffffff;
	font-size: 16px;
	display: none !important;
}

.mine .wrapper .content .finance {
	position: absolute;
	display: flex;
	align-items: center;
	top: -28px;
	left: 20px;
	right: 20px;
	height: 65px;
	background-color: #fff;
	border-radius: 15px;
	box-shadow: 0 1.5px 1px 0 #e4e4e7;
}
.mine .wrapper .content .finance .line {
	width: 3px;
	height: 40px;
	background-color: #ccc;
}
.mine .wrapper .content .finance .finance-item {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}
.mine .wrapper .content .finance .finance-item .text {
	margin-left: 10px;
	font-size: 15px;
	color: #c24491;
	font-weight: 500;
}
.mine .wrapper .content .finance .finance-item .icon {
	font-size: 20px;
	color: #c24491;
}
.mine .wrapper .content .menu {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	background-color: #fff;
	border-radius: 15px;
	box-shadow: 0 1.5px 1px 0 #e4e4e7;
}
.mine .wrapper .content .menu .menu-item {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 50%;
	height: 70px;
	line-height: 1.4;
}
.mine .wrapper .content .menu .menu-item:nth-child(even) {
	padding-right: 10px;
}
.mine .wrapper .content .menu .menu-item .menu-item-label {
	font-size: 14px;
	color: #c24491;
	font-weight: 500;
}
.mine .wrapper .content .menu .menu-item .menu-item-icon {
	margin: 15px;
	width: 40px;
	height: 40px;
	-o-object-fit: contain;
	object-fit: contain;
}
.mine .wrapper .content .wallet {
	margin-top: 50px;
	padding: 0 20px;
	background-color: #fff;
	border-radius: 15px;
	box-shadow: 0 1.5px 1px 0 #e4e4e7;
}
.mine .wrapper .content .wallet .part-1 {
	display: flex;
	align-items: center;
	height: 40px;
}
.mine .wrapper .content .wallet .font-primary-color {
	color: #8c71d2;
	font-size: 15px;
	margin: 20px 0 5px;
}
.font-gray {
	color: #868686;
}
.mine .wrapper .content .wallet .part-2 {
	display: flex;
	align-items: center;
	height: 80px;
}
.mine .wrapper .content .wallet .part-2 .balance {
	flex: 1;
	font-size: 28px;
	color: #775fd9;
	font-weight: 700;
}
.mine .wrapper .content .wallet .van-hairline--bottom::after {
	border-bottom-width: 3px;
}
.mine .wrapper .content .wallet .part-2 .refresh-btn {
	margin-left: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 35px;
	height: 35px;
	font-size: 18px;
	border-radius: 50%;
	color: #ffffff;
	background-color: #c24491;
}
.box-mine {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
}
.box-mine .item-box-mine {
	flex-basis: 33.33%;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	box-sizing: border-box;
	height: 100%;
	padding: 16px 8px;
	background-color: #fff;
	border-right: 1px solid #ebedf0;
	border-bottom: 1px solid #ebedf0;
}
.box-mine .item-box-mine:nth-child(3n) {
	border-right: 0;
}
.box-mine .item-box-mine img {
	width: 28px;
	height: 28px;
	border-radius: 100%;
	object-fit: cover;
	margin-bottom: 8px;
}
.box-mine .item-box-mine span {
	font-size: 12px;
	color: #646566;
	word-break: break-all;
}
.cskh {
	position: absolute;
	width: 32px;
	height: 32px;
	top: 15px;
	right: 60px;
}
.cskh img {
	width: 100%;
	height: 100%;
}
::v-deep .van-button--small {
	font-size: 20px;
	padding: 10px 15px;
}
.sign-out {
	margin: 20px auto;
	max-width: 400px;
	height: 40px;
	line-height: 40px;
	border-radius: 20px;
	color: #fff;
	font-size: 16px;
	font-weight: bold;
	border: none;
	background: linear-gradient(90deg, #f2fe30, #09aa5c);
	display: block;
}
.icon-language {
	position: absolute;
	top: 5px;
	right: 105px;
}
</style>
