<template>
	<div class="convention-hall page">
		<van-nav-bar class="nav-bar" :title="$t('heaven')" />
		<div class="convention-item">
			<van-tabs animated sticky line-width="100px" :swipeable="true">
				<van-tab :title="$t('city')">
					<div class="card">{{ $t('text1') }}</div>
					<div class="address">
						<van-row type="flex" justify="" v-for="(val, key) in addlist" :key="key">
							<van-col span="" @click="addgo(v)" v-for="(v, k) in val" :key="k">{{ v.name }}</van-col>
						</van-row>
					</div>
				</van-tab>
				<van-tab title="Giới thiệu nền tảng">
					<div class="card">{{ $t('text1') }}</div>
					<div class="rig-box">
						<p class="rig-title">{{ $t('text2') }}</p>
						<p class="rig-content">{{ $t('text3') }}</p>
						<p class="rig-title">{{ $t('text4') }}</p>
						<p class="rig-content">{{ $t('text5') }}</p>
						<p class="rig-content">{{ $t('text1') }}</p>
					</div>
				</van-tab>
			</van-tabs>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			addlist: [
				{
					0: 'Hồ Chí Minh',
					1: 'Hà Nội',
					2: 'Đà Nẵng',
					3: 'Vũng Tàu'
				},
				{
					0: 'Quy Nhơn',
					1: 'Đà Lạt',
					2: 'Hải Phòng',
					3: 'Bắc Ninh'
				}
			]
		};
	},
	methods: {
		addgo(data) {
			if (!localStorage.getItem('token')) {
				this.$router.push({ path: '/Login' });
			} else {
				this.$router.push({ path: '/list?id=' + data.id + '&name=' + data.name });
			}
		},
		getAddress() {
			this.$http({
				method: 'get',
				url: 'address_list'
			}).then(res => {
				this.addlist = res.data;
			});
		}
	},
	created() {
		this.getAddress();
	}
};
</script>

<style lang="less" scoped>
.page {
	background-color: #f2f2f5;
}
.nav-bar {
	background: #d80b2a;
	height: 40px;
}
.van-nav-bar {
	line-height: 40px;
}
::v-deep .van-col {
	padding: 0!important;
	width: 50%;
	text-align: center;
}
::v-deep .van-nav-bar__title {
	max-width: 80%;
	margin: 0 auto;
	color: #ffffff;
	font-size: 18px;
}
::v-deep .van-nav-bar__content {
	height: 40px;
}
.van-sidebar {
	width: 180px;
}
.convention-hall {
	display: flex;
	flex-direction: column;
	bottom: 20px;
	background: #f2f2f5;
}
::v-deep .van-tab {
	font-size: 16px;
	line-height: 40px;
	font-weight: bold;
}
::v-deep .van-tabs__line {
	background: linear-gradient(90deg, #f2fe30, #09aa5c);
}
::v-deep .van-tabs--line .van-tabs__wrap {
	height: 40px;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
	padding: 0 23px;
}
.card {
	background-color: #8a637d;
	padding: 10px;
	width: 95%;
	color: white;
	font-size: 15px;
	margin: 20px auto 10px auto;
	border-radius: 10px;
	line-height: 1.3;
}
::v-deep .van-row--flex {
	line-height: 40px;
	flex-flow: row wrap;
}
.rig-box {
	width: 95%;
	margin: 0.625rem auto;
}
.rig-title {
	color: #d161ac;
	font-size: 18px;
	margin: 25px 0 5px
}
.rig-content {
	font-size: 17px;
	// margin-top: 10px;
}
.address {
	width: 90%;
	margin: 0 auto;
}
.convention-item {
	background: #f2f2f5;
	padding-bottom: 100px;
}
</style>
